import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useConnect, useEnsName, useNetwork, useSwitchNetwork, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import {
  Box, Flex, Image, Link, Button, Menu, MenuButton, MenuList, MenuItem, Select, Text, IconButton, Drawer,
  DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, Stack,
  background,
  border,
  useColorMode
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { CONFIG } from '../../config';
import Logo from '../../assets/CrestsXLogo.svg';
import Logo2 from '../../assets/CrestsX Logo-05 3 Light Blue 1.svg';

const Navbar = ({ children }: any) => {
  const [language, setLanguage] = useState('EN');
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buttonStyles = {
    padding: '0.5rem 1rem',
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    borderRadius: '0.25rem',
    background: '#0A1534',          // Normal background color
    color: 'white',                 // Normal text color
    borderColor: '#60A5FA',
    _hover: {
      bg: 'linear-gradient(to left, #02AFFF, #0259BA)', // Hover background color
      color: 'white',                                   // Hover text color
      borderColor: 'black',                             // Optional: Change border color on hover
      filter: 'brightness(1.2)',
      cursor: 'pointer',
    },
    _disabled: {
      filter: 'saturate(40%)',
      cursor: 'default',
    },
  };

  return (
    <div>


      <Box as="header" bg="#03091C" borderBottom="1px solid #243B5E" p="0.8rem 2rem" position="sticky" top="0" zIndex="1000">
        <Flex justify="space-between" align="center">
          <Flex align="center">
            <Link href="/" ml={{ base: '0', md: '10ch' }}>
              <Image
                src={!hovered ? Logo : Logo2}
                alt="Logo"
                height="40px"
                mr="2rem"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              />
            </Link>
            <Flex as="nav" gap="2rem" align="center" display={{ base: 'none', md: 'flex' }}>
              <Link href="/about" color="white" _hover={{ color: '#80A3D2', textDecoration: 'none' }} fontSize="1rem" fontFamily="Poppins">About</Link>
              {/* <Menu>
              <MenuButton as={Button} variant="link" color="white" _hover={{ color: '#80A3D2', textDecoration: 'none' }} fontSize="1rem" fontFamily="Poppins" fontWeight='regular' >
                Product
              </MenuButton>
              <MenuList bg="#020611" borderColor="#243B5E" borderRadius="0.25rem" mt={2}>
                <MenuItem as={Link} href="#product1" color="#9ECAFF" bg='#020611' _hover={{ bg: '#575757', color: 'white', textDecoration: 'none' }}>Product 1</MenuItem>
                <MenuItem as={Link} href="#product2" color="#9ECAFF" bg='#020611' _hover={{ bg: '#575757', color: 'white', textDecoration: 'none' }}>Product 2</MenuItem>
                <MenuItem as={Link} href="#product3" color="#9ECAFF" bg='#020611' _hover={{ bg: '#575757', color: 'white', textDecoration: 'none' }}>Product 3</MenuItem>
              </MenuList>
            </Menu> */}
              {/* <Link href="#community" color="white" _hover={{ color: '#80A3D2', textDecoration: 'none' }} fontSize="1rem" fontFamily="Poppins">Community</Link>
            <Link href="#referral" color="white" _hover={{ color: '#80A3D2', textDecoration: 'none' }} fontSize="1rem" fontFamily="Poppins">Referral</Link> */}
              <Link href="https://docs.crestsx.com/" color="white" _hover={{ color: '#80A3D2', textDecoration: 'none' }} fontSize="1rem" fontFamily="Poppins">Whitepaper</Link>
            </Flex>
          </Flex>
          <Flex align="center" gap="1rem">
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              bg="#03091C"
              color="white"
              p="0.5rem"
              cursor="pointer"
              width="90px"
              display={{ base: 'none', md: 'block' }}
            >
              <option value="EN">EN</option>
              <option value="CN">CN</option>
            </Select>
            <Button
              sx={buttonStyles}
              display={{ base: 'none', md: 'block' }}
              onClick={() => navigate('/comingsoon')}
            // onClick={() => window.open('https://crestsx.vercel.app/')}
            >
              Launch App
            </Button>
            {/* {isConnected ? (
            <Flex align="center" gap="1rem" display={{ base: 'none', md: 'flex' }}>
              <Text fontSize="1rem" color="white">Connected to {ensName ? `${ensName} ` : address}</Text>
              <Button onClick={() => disconnect()} sx={buttonStyles}>Disconnect</Button>
            </Flex>
          ) : (
            <Button onClick={() => connect()} sx={buttonStyles} display={{ base: 'none', md: 'block' }}>Connect wallet</Button>
          )} */}
            <IconButton
              icon={<HamburgerIcon />}
              aria-label="Open Menu"
              display={{ base: 'block', md: 'none' }}
              onClick={onOpen}
              variant="ghost" // Change to "ghost" for a transparent button
              color="white" // Explicitly set the icon color to white
              _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }} // Optional hover effect
            />
          </Flex>
        </Flex>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg="#020611">
            <DrawerCloseButton />
            <DrawerHeader color="white">Menu</DrawerHeader>
            <DrawerBody>
              <Stack spacing={4} align="start">
                <Link href="/about" onClick={onClose} color="white" _hover={{ color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }}>
                  About
                </Link>
                {/* <Menu>
                <MenuButton as={Button} variant="link" color="white" _hover={{ color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }}>
                  Product
                </MenuButton>
                <MenuList bg="#020611" borderColor="#243B5E" borderRadius="0.25rem">
                  <MenuItem as={Link} href="#product1" color="#9ECAFF" bg="#020611" _hover={{ bg: '#575757', color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }} onClick={onClose}>
                    Product 1
                  </MenuItem>
                  <MenuItem as={Link} href="#product2" color="#9ECAFF" bg="#020611" _hover={{ bg: '#575757', color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }} onClick={onClose}>
                    Product 2
                  </MenuItem>
                  <MenuItem as={Link} href="#product3" color="#9ECAFF" bg="#020611" _hover={{ bg: '#575757', color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }} onClick={onClose}>
                    Product 3
                  </MenuItem>
                </MenuList>
              </Menu>
              <Link href="#community" onClick={onClose} color="white" _hover={{ color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }}>
                Community
              </Link>
              <Link href="#referral" onClick={onClose} color="white" _hover={{ color: 'rgba(158, 202, 255, 0.8)', textDecoration: 'none' }}>
                Referral
              </Link> */}
                <Link href="https://docs.crestsx.com/" color="white" _hover={{ color: '#80A3D2', textDecoration: 'none' }} fontSize="1rem" fontFamily="Poppins">
                  Whitepaper
                </Link>
                <Select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  bg="#03091C"
                  color="white"
                  border="1px solid #243B5E"
                  p="0.5rem"
                  cursor="pointer"
                  width="90px"
                >
                  <option value="EN">EN</option>
                  <option value="CN">CN</option>
                </Select>
                <Button
                  sx={buttonStyles}
                  onClick={() => {
                    onClose();
                    window.open('https://crestsx.vercel.app/', '_blank');
                  }}
                >
                  Launch App
                </Button>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
      {children}
    </div>
  );
};

export default Navbar;
