import React, { useState } from 'react';
import { Box, Heading, Text, Image, Link, Flex, SimpleGrid, Input, Button, Select } from '@chakra-ui/react';
import useSlidesData from '../../hooks/useSlidesData';

const AllArticles = () => {
  const { slidesData, loading } = useSlidesData(); // Use the hook to fetch articles
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc'); // Default to 'New to Old'
  const articlesPerPage = 9;

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page
  };

  // Handle sort order change
  const handleSortOrderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOrder(event.target.value as 'asc' | 'desc');
  };

  // Sort slides based on date and sortOrder
  const sortedSlides = [...slidesData].sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

  // Filter slides based on search term
  const filteredSlides = sortedSlides.filter((slide) =>
    slide.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredSlides.slice(indexOfFirstArticle, indexOfLastArticle);

  const totalPages = Math.ceil(filteredSlides.length / articlesPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Box bg="#03091C" color="white" py={10} px={6}>
      <Flex mx="10%" mb={6} align="center" gap={4}>
        <Heading as="h2" size="lg" mr={20}>
          Articles
        </Heading>
        <Input
          placeholder="Search by title"
          value={searchTerm}
          onChange={handleSearchChange}
          mr={4}
        />
        <Select value={sortOrder} onChange={handleSortOrderChange} maxWidth="150px">
          <option value="desc">Newest</option>
          <option value="asc">Oldest</option>
        </Select>
      </Flex>

      {loading ? (
        <Text>Loading articles...</Text>
      ) : (
        <>
          <SimpleGrid columns={[1, null, 3]} spacing={10} mx="10%">
            {currentArticles.map((slide, index) => (
              <Link key={index} href={`/article/${slide.id}`} _hover={{ textDecoration: 'none' }}>
                <Box
                  bg="#1E2A38"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  _hover={{ bg: '#162230' }}
                  height="100%"
                >
                  <Image src={slide.image} alt={slide.title} mb={4} borderRadius="md" />
                  <Heading as="h3" size="md" mb={2}>
                    {slide.title}
                  </Heading>
                  <Text color="rgba(255, 255, 255, 0.5)" mb={4}>
                    {new Date(slide.date).toLocaleDateString()}
                  </Text>
                  <Text
                    color="rgba(255, 255, 255, 0.7)"
                    fontSize="sm"                // Reduced font size
                    maxHeight="60px"             // Limit the height of the text
                    overflow="hidden"            // Hide overflowing text
                    textOverflow="ellipsis"      // Add ellipsis for overflowing text
                    whiteSpace="nowrap"          // Prevent text from wrapping to a new line
                  >
                    {slide.description.replace(/(<([^>]+)>)/gi, '')}
                  </Text>
                </Box>
              </Link>
            ))}
          </SimpleGrid>

          <Flex justify="center" mt={6}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                mx={1}
                bg={index + 1 === currentPage ? 'blue.500' : ''}
                color="white"
                _hover={{ bg: 'blue.400' }}
              >
                {index + 1}
              </Button>
            ))}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default AllArticles;