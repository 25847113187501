import React from 'react';
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react';
import Faq from '../../components/Landing/Faq'

const ComingSoon = () => {
  return (
    <Box bg="#03091C" minH="100vh" py={10} px={6}>
      <Flex justify="center" align="center" direction="column" mb={30}>
        <Heading as="h1" size="xl" mb={6} color="white">
          Coming Soon
        </Heading>
        <Text fontSize="lg" color="rgba(158, 202, 255, 0.8)" mb={6} textAlign="center">
          Learn more about CrestsX and our mission to provide the best trading platform.
        </Text>
        {/* <Button colorScheme="blue" variant="solid">
          Learn more
        </Button> */}
      </Flex>
      {/* <Faq /> */}
    </Box>
  );
};

export default ComingSoon;