import { useEffect, useState } from 'react';

// Define the type for each post
interface Post {
  id: number;
  title: string;
  excerpt: string;
  link: string;
  date: string;
  author: number;
  tags: number[];
  categories: number[];
  image: string;       // New field for image URL
  description: string; // New field for description
}

// Define the return type of the hook
interface UseSlidesDataReturn {
  slidesData: Post[];
  loading: boolean;
}

const useSlidesData = (): UseSlidesDataReturn => {
  const [slidesData, setSlidesData] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://wordpress-1146915-5153715.cloudwaysapps.com/wp-json/wp/v2/posts?_embed');
        const data = await response.json();

        // Transform data into the desired format
        const formattedPosts: Post[] = data.map((post: any) => ({
          id: post.id,
          title: post.title.rendered,
          excerpt: post.excerpt.rendered,
          link: post.link,
          date: post.date,
          author: post.author,
          tags: post.tags,
          categories: post.categories,
          description: post.content.rendered, // Full content as description
          image: post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '', // Fetch image URL from embedded data
        }));

        setSlidesData(formattedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return { slidesData, loading };
};

export default useSlidesData;