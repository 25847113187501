import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Image, Flex, Tag, Button } from '@chakra-ui/react';
import useSlidesData from '../../hooks/useSlidesData'; // Import the hook

const Article = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const navigate = useNavigate();
  const { slidesData, loading } = useSlidesData(); // Use the hook to get the data
  const [article, setArticle] = useState<typeof slidesData[0] | undefined>(undefined);

  useEffect(() => {
    if (slidesData.length > 0 && articleId) {
      const foundArticle = slidesData.find((slide) => slide.id === parseInt(articleId));
      setArticle(foundArticle);
    }
  }, [slidesData, articleId]);

  if (loading) {
    return (
      <Flex bg="#03091C" color="white" py={10} px={6} align="center" justify="center" height="100vh">
        <Box textAlign="center">
          <Heading as="h2" size="lg">
            Loading article...
          </Heading>
        </Box>
      </Flex>
    );
  }

  if (!articleId || !article) {
    return (
      <Flex bg="#03091C" color="white" py={10} px={6} align="center" justify="center" height="100vh">
        <Box textAlign="center">
          <Heading as="h2" size="lg">
            Article not found
          </Heading>
          <Button mt={4} onClick={() => navigate('/article')}>
            Go to All Articles
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex bg="#03091C" color="white" py={10} px={6} align="center" justify="center" minHeight="100vh">
      <Box maxWidth="800px" textAlign="center">
        <Image src={article.image} alt={article.title} mb={4} mx="auto" />
        <Heading as="h2" size="lg" mb={4}>
          {article.title}
        </Heading>
        {/* <Text color="rgba(255, 255, 255, 0.7)" mb={2}>
          by {article.author}
        </Text> */}
        <Text color="rgba(255, 255, 255, 0.5)" mb={4}>
          {new Date(article.date).toLocaleDateString()}
        </Text>
        {/* <Flex gap={2} mb={4} justify="center">
          {article.tags.map((tag, index) => (
            <Tag key={index} size="sm" variant="solid" colorScheme="blue">
              {tag}
            </Tag>
          ))}
        </Flex> */}
        <Text
          color="rgba(255, 255, 255, 0.7)"
          textAlign="left"              // Left-align the text
          lineHeight="1.8"              // Increase line height for better readability
          fontSize="md"                 // Adjust font size
          mb={6}                        // Add bottom margin
          dangerouslySetInnerHTML={{
            __html: article.description.replace(
              /<strong>(.*?)<\/strong>/g,
              '<strong style="color: white;">$1</strong>'
            ),
          }}
        />
      </Box>
    </Flex>
  );
};

export default Article;