import React from 'react';
import styles from './Footer.module.css';
import Logo from '../../assets/CrestsXLogo.svg';
import { Box, Flex, Text, Link, Heading, Image, VStack, Grid } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box as="footer" bg="#1A2235" color="white" py={10}>
      <Box maxW="1200px" mx="auto" px={6}>
        {/* Logo Section */}
        <Flex justify="center" mb={8}>
          <Image
            src={Logo}
            alt="Logo"
            boxSize={{ base: "100px", md: "150px", lg: "200px" }} // Responsive sizes
            mx="auto" // Centers the image horizontally
          />
        </Flex>

        {/* Links Section */}
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }} // 1 column on extra small, 2 columns on small+
          gap={6}
          mb={8}
        >
          {/* About Column */}
          <VStack align="flex-start" spacing={2}>
            <Heading as="h4" size="sm" mb={2}>
              About
            </Heading>
            <Link href="https://docs.crestsx.com/" isExternal>
              Gitbook
            </Link>
            <Link href="https://docs.crestsx.com/" isExternal>
              Whitepaper
            </Link>
            <Link href="https://www.crestsx.com/about" isExternal>
              FAQ
            </Link>
            <Link href="https://www.crestsx.com/article" isExternal>
              Blog
            </Link>
          </VStack>

          {/* Product Column */}
          <VStack align="flex-start" spacing={2}>
            <Heading as="h4" size="sm" mb={2}>
              Product
            </Heading>
            <Link href="/comingsoon">Spot trade</Link>
            <Link href="/comingsoon">Referral</Link>
            <Link href="/comingsoon">Campaign</Link>
            <Link href="/comingsoon">Portfolio</Link>
          </VStack>

          {/* Resource Column */}
          <VStack align="flex-start" spacing={2}>
            <Heading as="h4" size="sm" mb={2}>
              Resource
            </Heading>
            <Link href="https://www.crestsx.com/article" isExternal>
              Learning center
            </Link>
            <Link href="/comingsoon">Media kit</Link>
            <Link href="/comingsoon">API doc</Link>
          </VStack>

          {/* Community Column */}
          <VStack align="flex-start" spacing={2}>
            <Heading as="h4" size="sm" mb={2}>
              Community
            </Heading>
            <Link href="https://linktr.ee/crestsx" isExternal>
              LinkTree
            </Link>
            <Link href="https://x.com/crestsx" isExternal>
              X
            </Link>
            <Link href="https://medium.com/@CrestsX" isExternal>
              Medium
            </Link>
            <Link href="https://discord.com/invite/fjPe68DqN8" isExternal>
              Discord
            </Link>
            <Link href="https://t.me/crestsx_official" isExternal>
              Telegram
            </Link>
          </VStack>
        </Grid>

        {/* Footer Bottom */}
        <Flex
          justify="space-between"
          align="center"
          borderTop="1px solid #243B5E"
          pt={6}
          flexWrap="wrap"
        >
          <Text fontSize="sm">Copyright © 2023 CrestsX. All rights reserved.</Text>
          <Flex gap={4} mt={{ base: 4, md: 0 }}>
            <Link href="/comingsoon">Terms of use</Link>
            <Link href="/comingsoon">Term of service</Link>
            <Link href="/comingsoon">Privacy policy</Link>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;