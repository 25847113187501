import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import Slider from 'react-slick';
import useSlidesData from '../../hooks/useSlidesData';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slice = () => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const { slidesData, loading } = useSlidesData();
  const navigate = useNavigate();

  const handleSlideClick = (id: number) => {
    navigate(`/article/${id}`);
  };

  const settings = {
    infinite: true,
    dots: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  if (loading) {
    return <Text>Loading slides...</Text>;
  }

  return (
    <Box color="white" py={10} px={6}>
      <Flex justify="space-between" align="center" mb={6} mx="10%">
        <Heading as="h2" size="lg" fontFamily="Poppins">
          CrestsX Update
        </Heading>
        <Text
          color="rgba(158, 202, 255, 0.8)"
          cursor="pointer"
          onClick={() => {
            navigate('/article');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}

          _hover={{
            color: "white",               // Change text color on hover
            textDecoration: "underline",  // Add underline on hover
          }}
        >
          View all
        </Text>
      </Flex>

      <Flex justifyContent="center" mx="10%">
        <Box position="relative" width="100%" maxW="1200px">
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {slidesData.map((slide) => (
              <Box
                key={slide.id}
                p={4}
                cursor="pointer"
                onClick={() => handleSlideClick(slide.id)}
              >
                <Box
                  // height={{ base: '200px', md: '100%' }}
                  height={{ base: '160px', md: "180px", xl: '200px' }}
                  width={{ base: "100%", md: "100%" }}
                  backgroundImage={`url(${slide.image})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  borderRadius="10px"
                  mb={4}
                />
                <Heading
                  as="h3"
                  size="md"
                  fontFamily="Poppins"
                  mb={2}
                  noOfLines={2}
                  dangerouslySetInnerHTML={{ __html: slide.title }}
                />
                <Text fontSize="sm" color="rgba(255, 255, 255, 0.8)" noOfLines={3}>
                  {slide.excerpt.replace(/(<([^>]+)>)/gi, '')}
                </Text>
              </Box>
            ))}
          </Slider>
        </Box>
      </Flex>
    </Box>
  );
};

export default Slice;