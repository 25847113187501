import React from 'react';
import { configureChains, createClient, WagmiConfig, Chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { ChakraProvider } from '@chakra-ui/react'
import './App.css';
import styles from './App.module.css';

import Navbar from './components/Navbar/Navbar';
import Routes from './Routes';
import Footer from './components/Footer/Footer';
import { CONFIG } from './config'; // Import config

const jfinTestnet: Chain = {
  id: CONFIG.TARGET_CHAIN_ID,
  name: CONFIG.CHAIN_NAME,
  network: CONFIG.NETWORK_NAME,
  nativeCurrency: {
    name: CONFIG.CHAIN_NAME,
    symbol: CONFIG.CHAIN_SYMBOL,
    decimals: CONFIG.DECIMALS,
  },
  rpcUrls: {
    default: CONFIG.RPC_URL,
  },
  blockExplorers: {
    default: { name: CONFIG.EXPLORERS_NAME, url: CONFIG.BLOCK_EXPLORER_URL },
  },
  testnet: CONFIG.TEST_NET,
};

const { provider, webSocketProvider } = configureChains(
  [jfinTestnet],
  [publicProvider()]
);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors: [new InjectedConnector({ chains: [jfinTestnet] })],
});



function App() {
  return (
    <ChakraProvider>
      <WagmiConfig client={client}>
        <div className={styles.background}>
          <Navbar >
            <Routes />
            <Footer />
          </Navbar>
        </div>
      </WagmiConfig>
    </ChakraProvider>
  );
}

export default App;
